import axios from 'axios';

export const getCourseList = async () => {
  try {
    const response = await axios.get('/api/courses_in_org_retrieve');
    return response.data;
  } catch (error) {
    //console.error('Error fetching course list:', error);
    throw error;
  }
};

export const getAssociatedCoursesListForAdmin = async () => {
  try {
    const response = await axios.get('/api/admin_assoc_courses_retrieve');
    return response.data;
  } catch (error) {
    //console.error('Error fetching course list:', error);
    throw error;
  }
};

export const getAssociatedCoursesListForUser = async () => {
  try {
    const response = await axios.get('/api/user_assoc_courses_retrieve');
    return response.data;
  } catch (error) {
    //console.error('Error fetching course list:', error);
    throw error;
  }
};
