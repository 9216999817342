import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingIcon from '../assets/LoadingLogo.gif';

const AdminProtectedRoute = ({ children, requiredOrgId }) => {
    const { isAuthenticated, orgId, loading } = useContext(AuthContext);
    const location = useLocation();

    console.log('AdminProtectedRoute state:', { isAuthenticated, orgId, loading, requiredOrgId });

    if (loading) {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <img
                    src={LoadingIcon}
                    alt="Screen loading"
                    style={{
                        width: '3rem',
                        height: '3rem',
                        display: 'block',
                        marginLeft: '1.5rem',
                    }}
                />
                Loading...
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login/admin" state={{ from: location }} replace />;
    }

    if (requiredOrgId && String(orgId) !== String(requiredOrgId)) {
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export default AdminProtectedRoute;