import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Snackbar,
  Stack,
  Typography,
} from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ShareFilesModal( { open, onClose, shareFiles, share_code }) {
  const fileCount = shareFiles ? shareFiles.length : 0;
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'primary',
  });
  const snackbarRef = useRef(null);
  const redirectTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (redirectTimeoutRef.current) {
        clearTimeout(redirectTimeoutRef.current);
      }
    };
  }, [open]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(share_code);
      setSnackbar({
        open: true,
        message: 'Share code copied successfully!',
        color: 'success',
      });
      
      // Focus on the snackbar for screen readers
      if (snackbarRef.current) {
        snackbarRef.current.focus();
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to copy share code. Please try again.',
        color: 'danger',
      });
    }
  };
  
  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <Grid container spacing={2} mx="auto">
            <Grid xs={12} justifyContent="flex-end">
              <ModalClose
                aria-label="Close Dialog"
               />
            </Grid>
            <Grid xs={12}>
              <Typography level="h1" mb={3}>Share Course Data</Typography>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                mb={4}
                >
                    <Typography level="body-lg">
                      Send this share code to a colleague to allow them to import the data from this course to their course.
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>Share Code</FormLabel>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: "1.5rem"}}>
                        <Input
                          variant="outlined"
                          color="primary"
                          value={share_code}
                          sx={{  minWidth: '300px', marginRight: '10px' }}
                        />
                        <Button startDecorator={<ContentCopyIcon />} onClick={handleCopy} variant="solid">Copy</Button>                      
                      </Box>
                    </FormControl>
                  <Typography level="title-lg">
                      The following {fileCount} {fileCount === 1 ? 'file' : 'files'} will be shared (scroll to view all):
                  </Typography>
              </Stack>
              <Box
                mb={4}
                sx={{
                  maxHeight:'40vh',
                  overflow:'auto'
                }}
                >
                <List sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "8px",
                    }}>
                  {shareFiles && shareFiles.length > 0 ? (
                    shareFiles.map((file, index) => (
                      <ListItem key={index}>
                        {file.title}
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <Typography level="body-md" color="neutral">
                        No files selected for sharing.
                      </Typography>
                    </ListItem>
                  )}
                </List>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Button variant="outlined" onClick={onClose}>
                  Close
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Snackbar
        open={snackbar.open}
        color={snackbar.color}
        size="md"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        startDecorator={snackbar.color === 'success' ? <CheckCircleIcon /> : <ErrorOutlineIcon />}
        ref={snackbarRef}
        tabIndex={-1}
        role="status"
        aria-live="polite"
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        {snackbar.message}
      </Snackbar>
    </React.Fragment>
  );
}
