import axios from 'axios';

export const getOrgProfessors = async () => {
  try {
    const response = await axios.get('/api/course_professors_retrieve');
    return response.data;
  } catch (error) {
    //console.error('Error fetching course professors:', error);
    throw error;
  }
};

export const getCurrentAdmin = async () => {
  try {
    const response = await axios.get('/api/get_current_admin');
    return response.data;
  } catch (error) {
    //console.error('Error fetching current course professor:', error);
    throw error;
  }
}