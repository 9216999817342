import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import axios from 'axios';
import {
    Accordion,
    AccordionGroup,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Card,
    FormControl,
    FormLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    Snackbar,
    Stack,
    Textarea,
    Typography
} from '@mui/joy';
import ShareIcon from '@mui/icons-material/Share';
import HelpIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerCard from './QuestionAnswerCard';
import Container from './Container';
import LogoIcon from '../assets/logoMobile.svg';
import LoadingIcon from '../assets/LoadingLogo.gif';
import CourseCheck from '../components/CourseCheck';

const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
    whiteSpace: 'nowrap',
};

export default forwardRef(function Chatbot(props, ref) {
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
    const [query, setQuery] = useState('');
    const [error, setError] = useState(null);
    const [errorText, setErrorText] = useState('');
    const [priorQAndResponse, setPriorQAndResponse] = useState('');
    const [isInitializing, setIsInitializing] = useState(true);
    const MINIMUM_LOADING_TIME = 2000; // 2 seconds, adjust as needed
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [index, setIndex] = useState(0);
    const [orgId, setOrgId] = useState(null); 
    const [courseId, setCourseId] = useState(null);  
    const maxLength = 500;
    const textareaRef = useRef(null);
    const announcerRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isSwitchingCourse, setIsSwitchingCourse] = useState(false);

    useEffect(() => {
        document.title = "Preview - All Day TA";
    }, []);

    useImperativeHandle(ref, () => ({
        resetChatbot: (courseId) => {
            setIsSwitchingCourse(true);
            setQuestionsAndAnswers([]);
            setQuery('');
            setPriorQAndResponse('');
            setIsFirstLoad(true);
            initializeChatbotData(courseId);
        }
    }));
  
    useEffect(() => {
        initializeChatbotData();
    }, []);

    const initializeChatbotData = async (courseId = null) => {
        const startTime = Date.now();
        try {
            const url = courseId 
                ? `/api/initialize_chatbot?course_id=${courseId}`
                : '/api/initialize_chatbot';
            const response = await axios.get(url);
                if (response.status === 200) {
                    const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);
                
                setTimeout(() => {
                    setIsInitializing(false);
                    setIsSwitchingCourse(false);
                    setOrgId(response.data.org_id);
                    setCourseId(response.data.course_id);
                    // Reset the chatbot state for the new course
                    setQuestionsAndAnswers([]);
                    setPriorQAndResponse('');
                    setIsFirstLoad(true);
                }, remainingTime);
            } else {
                //console.error('Error initializing chatbot data:', response);
                setIsInitializing(false);
                setIsSwitchingCourse(false);
                setError('general_error');
            }
        } catch (error) {
            //console.error('Error initializing chatbot data:', error);
            setIsInitializing(false);
            setIsSwitchingCourse(false);
            setError('general_error');
        }
    };

    const HelpfulTips = () => (
        <Grid container spacing={2} mt={1} xs={12} sx={{ alignItems:'flex-start'}}>
            <Grid>
                <img
                    src={LogoIcon}
                    alt=""
                    style={{
                        width: '3rem',
                        height: '3rem',
                        display: 'block',
                        marginLeft: '1.5rem',
                    }}
                />
            </Grid>
            <Grid xs sx={{ height: 'auto', flexGrow: '1' }}>
                <Typography level="h2" mb={2}>Preview your All Day TA</Typography>
                <Typography level="body-lg" mb={2}>I am the virtual TA you have trained with all of your readings, course materials, lecture content, and slides. </Typography>
                <AccordionGroup size="lg">
                  <Accordion
                      expanded={index === 0}
                      onChange={(event, expanded) => {
                        setIndex(expanded ? 0 : null);
                        }}
                    >
                    <AccordionSummary>Here are types of questions you can try:</AccordionSummary>
                    <AccordionDetails>
                      <List component="ul" marker="disc" size="lg" sx={{ "--ListItem-minHeight": "36px" }}>
                        <ListItem>When is the first assignment due?</ListItem>
                        <ListItem>Can you explain <em>[this concept]</em> in relation to the lecture on <em>[this topic]</em>?</ListItem>
                        <ListItem>In <em>[this situation]</em>, what would be the right approach if I wanted to <em>[do this]</em>?</ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [query]);

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && !event.shiftKey && query.trim() !== '') {
        event.preventDefault();
        // Announce that the question is being processed
        if (announcerRef.current) {
            announcerRef.current.textContent = "Processing your question. Please wait for the answer.";
        }

        // Use setTimeout to delay the handleAskQuestion call
        setTimeout(() => {
            handleAskQuestion();
        }, 50);
       }
    };

    const handleAskQuestion = async () => {
        if (query.trim() === '') {
            setErrorText('Please enter a question before submitting.');
            return;
        }
        setErrorText('');

        const currentQuery = query; // Store the current query
        setQuery(''); // Clear the form immediately
        setIsFirstLoad(false);

        if (announcerRef.current) {
            announcerRef.current.textContent = "Processing your question. Please wait for the answer.";
        }

        // Update priorQAndResponse with the last Q&A pair
        if (questionsAndAnswers.length > 0) {
            const lastQA = questionsAndAnswers[0]; // Get the most recent Q&A
            setPriorQAndResponse(JSON.stringify({
                question: lastQA.question,
                answer: lastQA.answer
            }));
        }

        const newQuestion = {
            id: questionsAndAnswers.length + 1,
            question: currentQuery, // Use the stored query
            answer: 'Processing...',
            isLoading: true,
            hasAnswer: true,
            question_log_org_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_course_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_question_id: -1, // set this as -1 while processing and update based on response provided in /api/chatbot_query
            question_log_user_id: -1 // set this as -1 while processing and update based on response provided in /api/chatbot_query
        };

        setQuestionsAndAnswers([newQuestion, ...questionsAndAnswers]);

        try {
            /*console.log("Submitting to LLM:", {
                query: currentQuery,
                prior_q_and_response: priorQAndResponse ? JSON.parse(priorQAndResponse) : null
            });*/

            const response = await axios.post('/api/chatbot_query', {
                query: currentQuery, // Use the stored query
                prior_q_and_response: priorQAndResponse,
                org_id: orgId,
                course_id: courseId 
            });

            const [newq_org_id, newq_course_id, newq_question_id, newq_user_id] = response.data.question_log_identifiers
            const jsonResponse = JSON.parse(response.data.response);

            const updatedQuestion = {
                ...newQuestion,
                answer: jsonResponse.answer,
                footnotes: jsonResponse.footnotes,
                isLoading: false,
                question_log_org_id: newq_org_id,
                question_log_course_id: newq_course_id,
                question_log_question_id: newq_question_id,
                question_log_user_id: newq_user_id
            };

            setQuestionsAndAnswers(prevState =>
                prevState.map(qa =>
                    qa.id === newQuestion.id ? updatedQuestion : qa
                )
            );

            setPriorQAndResponse(JSON.stringify({
                question: currentQuery,
                answer: jsonResponse.answer
            }));
        } catch (error) {
            //console.error('Error fetching chatbot response:', error);
            const updatedQuestion = {
                ...newQuestion,
                answer: 'An error occurred. Please try again later.',
                isLoading: false
            };
            setQuestionsAndAnswers(prevState =>
                prevState.map(qa =>
                    qa.id === newQuestion.id ? updatedQuestion : qa
                )
            );
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setErrorText('');
        if (inputValue.length <= maxLength) {
            setQuery(inputValue);
        } else {
            setQuery(inputValue.substring(0, maxLength));
            setSnackbarOpen(true);
        }
    }

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData('text');
        const newText = query + pastedText;
        if (newText.length <= maxLength) {
            setQuery(newText);
        } else {
            setSnackbarOpen(true);
            setQuery(newText.substring(0, maxLength));
        }
        event.preventDefault();
      };    

    if (isInitializing || isSwitchingCourse) {
        return (
            <Box role="alert" aria-live="polite">
                <Grid container spacing={2} mt={2} xs={12} sx={{ alignItems:'center', justifyContent: 'center'}}>
                    <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                        <img src={LoadingIcon} alt="" style={{ width: '6rem', height: '6rem', display: 'block' }} />
                        <Typography level="title-lg">{isSwitchingCourse ? "Switching Course..." : "Loading All Day TA"}</Typography>
                    </Stack>
                </Grid>     
            </Box>
        )
    }

    if (error === 'general_error') {
        return (
            <Box role="alert" aria-live="polite">
                <Grid container spacing={2} mt={2} xs={12} sx={{ alignItems:'center', justifyContent: 'center'}}>
                    <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                        <Typography level="h2">Error</Typography>
                        <Typography level="body-lg">An error occurred while loading the chatbot. Please try again later.</Typography>
                        <Button variant="solid" onClick={() => window.location.reload()}>
                            Retry
                        </Button>
                    </Stack>
                </Grid>
            </Box>
        )
    }

    return (
    <CourseCheck>
        <Container>
            <Typography 
                level="h1" 
                sx={visuallyHidden}
            >
                Preview All Day TA
            </Typography>
            <Grid container direction="row" justifyContent="center" sx={{ flex: 1, overflowY: 'auto', paddingBottom: '3rem' }}>
                <Grid xs={12} sm={12} md={8}>
                    <Stack direction="column" spacing={3} alignItems="center">
                        {isFirstLoad && <HelpfulTips />}
                        {questionsAndAnswers.slice().reverse().map((qa) => (
                            <QuestionAnswerCard
                                key={qa.id}
                                id={qa.id}
                                question={qa.question}
                                answer={qa.answer}
                                footnotes={qa.footnotes}
                                isLoading={qa.isLoading}
                                orgId={qa.question_log_org_id}
                                courseId={qa.question_log_course_id}
                                questionId={qa.question_log_question_id}
                                userId={qa.question_log_user_id}
                            />
                        ))}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" sx={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                <Grid xs={12} sm={12} md={8} sx={{ textAlign:'right'}}>
                    <Card variant="soft" orientation="vertical" sx={{ boxSizing: 'border-box', boxShadow: 'sm' }}>
                        <FormControl>
                            <FormLabel>Ask a question</FormLabel>
                            <Textarea
                                name="outlined"
                                color="primary"
                                value={query}
                                onChange={handleInputChange}
                                onPaste={handlePaste}
                                onKeyPress={handleKeyPress}
                                ref={textareaRef}
                                sx={{
                                    minHeight: '4rem',
                                    marginBottom: '0rem',
                                    overflow: 'hidden',
                                    resize: 'none',
                                }}
                            />
                            {errorText && <FormHelperText>{errorText}</FormHelperText>}
                        </FormControl>
                        <Stack direction="row" justifyContent="space-between" alignItems="center"spacing={2}>
                            {/*<Button
                                value="md"
                                variant="outlined"
                                endDecorator={<ArrowForwardIcon />}
                                color="neutral"
                            >
                                Ask as follow up
                            </Button>*/}
                            <Typography level="component-sm">
                                {query.length}/{maxLength} characters
                            </Typography>
                            <Button variant="solid" size="md" onClick={handleAskQuestion} aria-label={`Ask question: ${query}`}>
                                Ask
                            </Button>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
            <div 
                ref={announcerRef}
                aria-live="polite"
                aria-atomic="true"
                style={{ position: 'absolute', height: '1px', width: '1px', overflow: 'hidden', clip: 'rect(1px, 1px, 1px, 1px)' }}
            />
            <Snackbar
                autoHideDuration={8000}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                color="success"
                variant="soft"
                role="alert"
                aria-live="polite"
                tabIndex={-1}
            >
                You have exceeded 500 characters. Your text has been truncated.
            </Snackbar>
        </Container>
    </CourseCheck>
    );
});