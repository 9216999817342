import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/joy';
import Close from "@mui/icons-material/Close";
import './TipsPopUp.css';

const tipsList = [
    'Upload your handouts, slides, audio or video transcripts, readings, teaching notes, etc. Any file you upload will be used to answer questions.',
    'Rename your files using short descriptive names, this helps the AI find what it needs to answer questions.', 
    'Files set to “Active” are used by the AI when answering questions.',
];

export default function TipsPopUp( { onClose }) {
    return (
        <Box className="tip-container">
            <Stack direction="row-reverse" alignItems="center" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} className="flex-header">
                <Button endDecorator={<Close/>} variant='plain' onClick={onClose}>Hide Tips</Button>
                <Typography level="h2" mb={1}>
                    Helpful Tips for Getting Started
                </Typography>
                <Typography level="body-md">
                All Day TA is your classroom assistant, you can train it on the ways you like to teach, the concepts you want students to understand, and the phrasing you prefer. Here are some helpful tips:</Typography>
            </Stack>
            <Grid container spacing="2">
                {tipsList.map((tip, index) => (
                    <Grid xs={12} sm={4} mb={1} key={index} className="tip-item-container">
                        <Box className="grey-box"><Typography level="title-lg">{index+1}</Typography></Box>
                        <Box className="tip-item">
                            <Typography level="body-md">{tip}</Typography>
                        </Box>
                    </Grid>
                ))}
                <Typography level="title-md" mt={1}>For more helpful tips and FAQs, view our <Link href="https://www.alldayta.com/faqs" target="_blank" rel="noopener" underline="always">Frequently Asked Questions</Link>.</Typography>
            </Grid>
        </Box>
    );
}