import axios from 'axios';
import { useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Typography,
} from '@mui/joy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './DragDrop.css';
import DuplicateFileModal from './DuplicateFileModal';


export default function DragAndDropSyllabus({ setSyllabusFile, syllabusUploaded, isProcessing, checkDuplicates=false }) {
  const fileInputRef = useRef(null);
  const [duplicateFile, setDuplicateFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const supportedFileTypes = [
    'pdf', 'pptx', 'docx', 'txt', 'tex', 'md', 'html', 'htm'
  ];

  const isFileTypeSupported = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return supportedFileTypes.includes(extension);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;

    if (files.length > 1) {
      setErrorMessage('Please drop only one file');
      return;
    }

    const file = files[0];
    if (!isFileTypeSupported(file)) {
      setErrorMessage('Unsupported file type');
      return;
    }

    handleFileChange(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const extractTitlesFromResponse = (responseData) => {
    const titles = responseData.flatMap(file => [file.title, file.original_title]);
    return [...new Set(titles)]; // return a list with only unique titles
  };

  const removeFileExtension = (title) => {
    const lastDotIndex = title.lastIndexOf('.');

    if (lastDotIndex === -1) {
      return title;
    }

    return title.substring(0, lastDotIndex);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFileChange = async (file) => {
    setErrorMessage('');
    if (!isFileTypeSupported(file)) {
      setErrorMessage('Unsupported file type');
      return;
    }
    const isDuplicate = await checkForDuplicate(file);
    if (!isDuplicate) {
      setSelectedFile(file);
      setSyllabusFile(file);
    } else {
      setDuplicateFile(file.name);
      setModalOpen(true);
    }
  };

  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName.substring(lastDotIndex) : '';
  };

  const checkForDuplicate = async (file) => {
    if (checkDuplicates) {
      try {
        const response = await axios.get('/api/course_files_retrieve');
        const allCurrTitles = extractTitlesFromResponse(response.data);
  
        return allCurrTitles.some(title => 
          removeFileExtension(file.name) === title
        );
      } catch (error) {
        //console.error('Error checking for duplicate files:', error);
        return false;
      }
    }
  };

  return (
    <Box component="section" className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver} disabled={isProcessing} data-testid="file-drop-zone">
      {syllabusUploaded ? (
        <>
        <Typography level="body-lg">Drag and drop to replace your syllabus file here</Typography>
        <Typography level="body-lg">or</Typography>
        <Button variant='outlined' onClick={handleButtonClick} disabled={isProcessing}>Select File</Button>
        <Typography level="component-sm">Supported filetypes: .pdf, .pptx, .docx, .txt, .tex, .md, .html, .htm</Typography>
        </> 
      ) : (
        <>
        <Typography level="body-lg">Drag and drop your syllabus file here</Typography>
        <Typography level="body-lg">or</Typography>
        <Button variant='soft' color='primary' onClick={handleButtonClick}>Select File</Button>
        <Typography level="component-sm">Supported filetypes: .pdf, .pptx, .docx, .txt, .tex, .md, .html, .htm</Typography>
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        multiple={false} // Allow only one file
        accept=".pdf,.pptx,.docx,.txt,.tex,.md,.html,.htm"
      />
      {selectedFile && (
        <Box mt={2} textAlign="center">
          <Typography level="body-lg">Selected File: {selectedFile.name}</Typography>
          <a href={URL.createObjectURL(selectedFile)} target="_blank" rel="noopener noreferrer">Preview</a>
        </Box>
      )}
      {errorMessage && (
        <Alert 
          variant="soft" 
          color="danger" 
          size="md" 
          startDecorator={<ErrorOutlineIcon/>}
          role="alert"
          aria-live="polite"
        >
          {errorMessage}
        </Alert>
      )}
      <DuplicateFileModal modalOpen={modalOpen} closeModal={closeModal} duplicatedFiles={duplicateFile ? [duplicateFile] : []} />
    </Box>
  );
}