import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    Grid,
    FormControl,
    FormLabel,
    FormHelperText,
    IconButton,
    Input,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
  } from '@mui/joy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Container from '../components/Container';
import { AdminsListSkeleton } from '../components/LoadingSkeletons';
/*import TableStudentInvite from '../components/StudentTable';
import InviteStudentModal from '../components/InviteStudentsModal';*/
import { getCurrentAdmin, getOrgProfessors } from '../ProfessorsRetrieveAPI';
import CourseCheck from '../components/CourseCheck';

export default function Settings({ headerRef }) {
  const [orgId, setOrgId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [adminInSession, setAdminInSession] = useState(-1);
  const [courseAdmins, setCourseAdmins] = useState([]);
  const [courseUrl, setCourseUrl] = useState('');
  const [adminOptions, setAdminOptions] = useState([]);
  //Invite new admins
  const [professorOrg, setProfessorOrg] = useState('');
  const [professorName, setProfessorName] = useState('');
  const [professorEmail, setProfessorEmail] = useState('');
  //Status and Alerts
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    professorOrg: false,
    professorName: false,
    professorEmail: false
  });
  const [alertInfo, setAlertInfo] = useState({ show: false, message: '', severity: 'info' });
  const [formAlertInfo, setFormAlertInfo] = useState({ show: false, message: '', severity: 'info' });

  useEffect(() => {
      document.title = "Settings - All Day TA";
  }, []);

  //Gathering information about this course
  const fetchStatus = useCallback(async () => {
      setIsLoading(true);
      try {
          const response = await axios.get('/api/course');
          if (response.data && response.data[0]) {
              const courseData = response.data[0];
              const fetchedOrgId = courseData[0];
              const fetchedCourseId = courseData[1];
              const fetchedCourseUrl = courseData[11];
              setOrgId(fetchedOrgId);
              setCourseId(fetchedCourseId);
              setCourseUrl(fetchedCourseUrl);

              // Fetch admins for this course using the new endpoint
              const adminsResponse = await axios.get(`/api/course_admins/${fetchedCourseId}`);
              if (adminsResponse.data && adminsResponse.data.admins) {
                setCourseAdmins(adminsResponse.data.admins);
              }
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      } finally {
          setIsLoading(false);
      }
  }, []);


  useEffect(() => {
      if (headerRef.current) {
          headerRef.current.setOnCourseChange(fetchStatus);
      }
  }, [headerRef, fetchStatus]);

  useEffect(() => {
    const fetchCurrAdmin = async () => {
      try {
        const data = await getCurrentAdmin();
        //console.log("Fetched admin:", data.admin_id);
        setAdminInSession(data.admin_id);
      } catch (error) {
        //console.error('Error fetching course professor in session:', error);
      }
    }

    fetchCurrAdmin();
  }, []);

  useEffect(() => {
      fetchStatus();
  }, [fetchStatus]);

  const fetchAdmins = useCallback(async () => {
    try {
      const adminsResponse = await axios.get(`/api/course_admins/${courseId}`);
      if (adminsResponse.data && adminsResponse.data.admins) {
        setCourseAdmins(adminsResponse.data.admins);
      }
    } catch (error) {
      console.error('Error fetching course admins:', error);
    }
  }, [courseId]);

  // Get the other admins already in your org
  useEffect(() => {
    const fetchProfessors = async () => {
      try {
        const data = await getOrgProfessors();
        //console.log("Fetched org professor options:", data);
        setAdminOptions(data);
      } catch (error) {
        //console.error('Error fetching org professors:', error);
      }
    };
    fetchProfessors();
  }, []); 

  //Adding and removing admins (already in org) to this course
  const handleAddAdmin = async (newAdmin) => {
    if (newAdmin && !courseAdmins.some(admin => admin.admin_id === newAdmin.admin_id)) {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/admin_course_association', {
                admin_id: newAdmin.admin_id,
                course_id: courseId,
                operation: 'add'
            });

            if (response.status === 200) {
                setCourseAdmins(prevAdmins => [...prevAdmins, newAdmin]);
                setAlertInfo({
                    show: true,
                    message: 'Admin added successfully to this course',
                    severity: 'success'
                });
            }
        } catch (error) {
            console.error('Error adding admin or associating course:', error);
            setAlertInfo({
                show: true,
                message: 'Error adding admin or associating course: ' + error.message,
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    }
  };

  const handleRemoveAdmin = async (adminToRemove) => {
    if (adminToRemove.admin_id === adminInSession) {
        // Prevent removing the current user
        setAlertInfo({
            show: true,
            message: "You cannot remove yourself from the course.",
            severity: 'warning'
        });
        return;
    }

    if (courseAdmins.length <= 1) {
        // Prevent removing the last admin
        setAlertInfo({
            show: true,
            message: "You cannot remove the last admin from the course.",
            severity: 'warning'
        });
        return;
    }

    setIsLoading(true);
    try {
        const response = await axios.post('/api/admin_course_association', {
            admin_id: adminToRemove.admin_id,
            course_id: courseId,
            operation: 'remove'
        });

        if (response.status === 200) {
            setCourseAdmins(prevAdmins => prevAdmins.filter(admin => admin.admin_id !== adminToRemove.admin_id));
            setAlertInfo({
                show: true,
                message: "Admin removed successfully.",
                severity: 'success'
            });
        }
    } catch (error) {
        console.error('Error removing admin:', error);
        setAlertInfo({
            show: true,
            message: 'Error removing admin: ' + error.response?.data?.message || error.message,
            severity: 'error'
        });
    } finally {
        setIsLoading(false);
    }
  };

    //Inviting new admins to your org and this course
    const handleProfessorNameChange = (e) => {
        setProfessorName(e.target.value);
        if (errors.professorName) {
          setErrors(prev => ({ ...prev, professorName: false }));
        }
    };

    const handleProfessorEmailChange = (e) => {
        const newEmail = e.target.value;
        setProfessorEmail(newEmail);
        
        if (errors.professorEmail) {
          setErrors(prev => ({ ...prev, professorEmail: false }));
        }
      };

    const handleInvite = async () => {
        console.log('handleInvite function called');
        setIsSubmitted(true);

        const newErrors = {
          professorName: !professorName.trim(),
          professorEmail: !professorEmail.trim(),
        };

        setErrors(newErrors);

        // Check if the email already exists in adminOptions
        const existingAdmin = adminOptions.find(admin => admin.admin_email.toLowerCase() === professorEmail.toLowerCase());
        
        if (existingAdmin) {
          if (courseAdmins.some(admin => admin.admin_id === existingAdmin.admin_id)) {
            setFormAlertInfo({
              show: true,
              message: 'This admin is already added to the course.',
              severity: 'warning'
            });
          } else {
            setFormAlertInfo({
              show: true,
              message: 'This admin is already in your organization.',
              severity: 'warning'
            });
          }
          return;
        }

        // If there are any errors, stop the submission
        if (newErrors.professorName || newErrors.professorEmail) {
          return;
        }

        // Check if orgId is available
        if (!orgId) {
          setFormAlertInfo({
            show: true,
            message: 'Organization ID is not available. Please try again later.',
            severity: 'error'
          });
          return;
        }

        const inviteData = {
          professorName,
          professorOrg: orgId,
          professorEmail,
          courseId
        };
        console.log('inviteData prepared:', inviteData);
  
        try {
          console.log('Sending request to /api/invite_admin_collaborator');
          console.log('Request payload:', JSON.stringify(inviteData));
          
          const response = await axios.post('/api/invite_admin_collaborator', inviteData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          console.log('Response received:', response);

          if (response.status === 200) {
            setFormAlertInfo({
              show: true,
              message: 'Admin invited successfully',
              severity: 'success'
            });
            console.log('Server response:', response.data);
        
            // Clear the form
            setProfessorName('');
            setProfessorEmail('');

            // Update the list of admins
            await fetchAdmins();
            const updatedOrgProfessors = await getOrgProfessors();
            setAdminOptions(updatedOrgProfessors);
          }
        } catch (error) {
          console.error('Error details:', error.response || error);
          setFormAlertInfo({
            show: true,
            message: 'Error inviting admin: ' + (error.response?.data?.error || error.message),
            severity: 'error'
          });
        }
    };

    return (
        <Container>
            <Typography level="h1">Settings</Typography>
            <CourseCheck>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={7}>
                    <Typography mt={3} mb={1} level="h2">Administrators</Typography>
                    {isLoading ? (
                      <AdminsListSkeleton count={5} /> // Adjust the count as needed
                    ) : (
                    <List sx={{  
                        "--ListItem-paddingX": "0px",
                        "--ListItem-paddingY": "8px"
                        }}>
                        {courseAdmins.map((admin, index) => (
                          <ListItem
                            key={index}
                            endAction={
                              <IconButton 
                                aria-label="Delete" 
                                size="sm" 
                                color="danger"
                                onClick={() => handleRemoveAdmin(admin)}
                                disabled={isLoading}
                              >
                                <CloseIcon />
                              </IconButton>
                            }
                          >
                            {admin.admin_name} ({admin.admin_email})
                          </ListItem>
                        ))}
                    </List>
                    )}
                    {alertInfo.show && (
                        <Alert 
                            sx={{ mt: 2, mb: 2 }} 
                            variant="soft"
                            color={alertInfo.severity}
                            role="alert"
                            aria-live="polite"
                            endDecorator={
                                <Button variant="soft" size="sm" color={alertInfo.severity} onClick={() => setAlertInfo({ ...alertInfo, show: false })}>
                                    Dismiss
                                </Button>
                            }
                        >
                            {alertInfo.message}
                        </Alert>
                    )}
                    <Divider sx={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}/>      
                    <Stack direction="column" spacing={1} alignItems="flex-start">
                      <FormControl sx={{ width: '100%' }}>
                        <FormLabel>Add an admin from your organization:</FormLabel>
                        <Autocomplete
                          variant="outlined"
                          color="primary"
                          clearOnBlur
                          options={adminOptions.filter(option => 
                            !courseAdmins.some(admin => admin.admin_id === option.admin_id)
                          ).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                          getOptionLabel={(option) => `${option.admin_name} (${option.admin_email})`}
                          placeholder="Type to search Professor/TA Admins"
                          onChange={(event, newValue) => {
                            console.log("Autocomplete onChange:", newValue);
                            if (newValue) {
                              handleAddAdmin(newValue);
                            }
                          }}
                          value={null}
                          disabled={isLoading}
                        />
                        <FormHelperText>
                            Select an admin to add them as a course administrator
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={12} md={5}>
                <Card variant="soft">
                <Stack direction="column" spacing={2} p={2}>
                    <Box>
                      <Typography level="title-lg">Don't see the admin in your organization?</Typography>
                      <Typography>Invite them to join your organization on All Day TA</Typography>
                    </Box>
                    {formAlertInfo.show && (
                        <Alert 
                            sx={{ mt: 2, mb: 2 }} 
                            variant="soft"
                            color={formAlertInfo.severity}
                            role="alert"
                            aria-live="polite"
                            endDecorator={
                                <Button variant="soft" size="sm" color={formAlertInfo.severity} onClick={() => setFormAlertInfo({ ...formAlertInfo, show: false })}>
                                    Dismiss
                                </Button>
                            }
                        >
                            {formAlertInfo.message}
                        </Alert>
                    )}
                    <FormControl error={errors.professorName}>
                        <FormLabel>Name</FormLabel>
                        <Input variant="outlined"
                          color=
                            {errors.professorName
                              ? "danger"
                              : "primary"
                              } 
                          required value={professorName} onChange={(e) => setProfessorName(e.target.value)} />
                          <FormHelperText>
                            {errors.professorName
                            ? "Professor name is required"
                            : null }
                          </FormHelperText>
                      </FormControl>
                      <FormControl error={errors.professorEmail}>
                        <FormLabel>Email</FormLabel>
                        <Input 
                            variant="outlined"
                            color={errors.professorEmail ? "danger" : "primary"}
                            required 
                            value={professorEmail} 
                            onChange={handleProfessorEmailChange}
                        />
                        <FormHelperText>
                            {errors.professorEmail ? "Professor email is required" : null}
                          </FormHelperText>
                      </FormControl>
                    <Button endDecorator={<ArrowForwardIcon/> } onClick={handleInvite}>Invite Professor</Button>
                    </Stack>
                    </Card>
                </Grid>
            </Grid>
            <Card variant='soft' sx={{ mb: 3, mt: 3, padding: '2.2rem' }}>
                <Typography level="h2">All Day TA Access</Typography>
                <Typography mb={2} mt={3} level="h4">Public URL</Typography>
                <Grid container>
                    <Grid xs={12} sm={9}>
                        <Link style={{ wordBreak: "break-all" }} href={`https://app.alldayta.com${courseUrl}`} target="_blank" underline="always" level="body-lg" variant="plain" startDecorator={<OpenInNewIcon/>}>{`app.alldayta.com${courseUrl}`}</Link>
                    </Grid>
                </Grid>
                {/*<Divider/>
                <Typography level="body-lg" mt={2.5}>Restrict access to students who have been invited to the course: </Typography>
                <Checkbox color="primary" size="lg" label="Require Student Login" />*/}
            </Card>
            {/* <Box>
                <Stack direction="row" spacing={2} alignItems="center" mt={3} mb={3}>
                    <Typography mr={2} level="h2">Students</Typography>
                    <InviteStudentModal />
                </Stack>
                <TableStudentInvite/>

            </Box>*/}
            </CourseCheck>
        </Container>
    );
}