import React from 'react';
import {  
    Box,
    Button,
    Grid,
    Stack,
    Typography,
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Container from '../components/Container';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';


export default function Unauthorized() {
    return (
        <Box>
            <header className="min-header-wrapper">
                <Box className="left-wrapper">
                    <a href="/login/admin" aria-label="Login"><img src={Logo} alt="All Day TA - Login" className="logo" /></a>
                    <a href="/login/admin" aria-label="Login" className="logo-mobile"><img src={LogoMobile} alt="All Day TA - Login" className="logo-mobile" /></a>
                </Box>
                <Box className="right-wrapper">
                    <Button
                        variant="plain"
                        component="a"
                        href="https://alldayta.com/support"
                        startDecorator={<HelpIcon />}
                        >
                        Contact Support
                    </Button>
                </Box>
            </header>
            <Container>
                <Grid container xs={12} mx='auto' spacing={2} justifyContent="space-around"
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60vh', // Ensure full viewport height
                }}>
                    <Grid container xs={12} sm={4}>
                        <Stack
                            direction="column"
                            alignItems="left"
                            spacing={3}
                            sx={{ width:'100%' }}
                            >
                            <Box>
                                <Button component="a" href="/login/admin" startDecorator={<ArrowBackIcon/>} size="sm" variant="plain">Return to Login</Button>
                            </Box>
                            <Box>
                                <Typography level="h1" mb={1}>Unauthorized Page</Typography>
                                <Typography mt={2} mb={1}>You don't have the correct permissions to view this page.</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}