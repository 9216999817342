import React from 'react';
import { 
  Box, 
  Button, 
  Card,
  Grid, 
  List,
  ListItem,
  Skeleton, 
  Stack, 
  Table,
  Typography 
} from '@mui/joy';
import FileUploadIcon from '@mui/icons-material/FileUpload';

//Loading Skeletons for Files

const SkeletonTableButtons = () => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} className="table-toolbar">
    <Stack direction="row" alignItems="center" spacing={1}>
      <Skeleton variant="rectangular" width={139} height={36} />
      <Skeleton variant="rectangular" width={100} height={36} />
      <Skeleton variant="rectangular" width={100} height={36} />
    </Stack>
    <Stack direction="row" alignItems="center" gap={2}>
        <Skeleton variant="rectangular" width={70} height={22} />
        <Skeleton variant="rectangular" width={55} height={32} />
        <Skeleton variant="rectangular" width={70} height={22} />
        <Skeleton variant="rectangular" width={32} height={32} />
        <Skeleton variant="rectangular" width={32} height={32} />
    </Stack>
  </Stack>
)

const SkeletonTableHeader = () => (
    <thead>
      <tr>
        <th width={22}>
          <Skeleton variant="rectangular" width={22} height={22} />
        </th>
        <th>
          <Typography><Skeleton>File Name</Skeleton></Typography>
        </th>
        <th>
          <Typography><Skeleton>Active</Skeleton></Typography>
        </th>
        <th>
          <Typography><Skeleton>Status</Skeleton></Typography>
        </th>
        <th sx={{textAlign:'right'}}>
          <Typography><Skeleton>Actions</Skeleton></Typography>
        </th>
      </tr>
    </thead>
)

const SkeletonTable = () => (
    <tbody>
        {[...Array(5)].map((_, index) => (
          <tr key={index}>
            <th scope="row" width={22}>
              <Skeleton variant="rectangular" width={22} height={22} />
            </th>
            <th scope="row">
              <Skeleton variant="rectangular" width={280} height={22} />
            </th>
            <td>
              <Skeleton variant="rectangular" width={22} height={22} />
            </td>
            <td><Skeleton variant="rectangular" width={100} height={22} /></td>
            <td>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1} >
                  <Skeleton variant="rectangular" width={32} height={32} />
                  <Skeleton variant="rectangular" width={32} height={32} />
              </Stack>
            </td>
          </tr>
          ))}
    </tbody>
);

export const TableLoadingSkeleton =() => (
    <Box>
        <SkeletonTableButtons />
        <Table sx={{ 
            '& thead th:nth-of-type(2)': { width: '50%' }, 
            '& tr > :nth-of-type(5)': { textAlign: 'right' }
        }}>
            
            <SkeletonTableHeader />
            <SkeletonTable />
        </Table>
    </Box>
)

//Loading Skeletons for Edit Course

const CourseInfoSkeleton = () => (
  <Grid container spacing={2}>
    <Grid xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ flexWrap: 'wrap' }}
        >
          <Typography level="h1">Edit Course Information</Typography>
        </Stack>
      <Skeleton variant="rectangular" width="100%" height={150} sx={{ mt: 2 }} />
    </Grid>
    <Grid xs={12} sm={6} pd={3} sx={{ boxSizing: 'border-box', width:'100%'}}>
      <Stack direction="column" spacing={3}>
        {[...Array(4)].map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height={40} />
        ))}
        <Box sx={{ mb: 3 }}>
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="70%" />
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            {[...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={100} height={40} />
            ))}
          </Box>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="70%" />
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            {[...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={100} height={40} />
            ))}
          </Box>
        </Box>
      </Stack>
    </Grid>
    <Grid xs={12} sm={6}>
      <Box sx={{ mb: 3 }}>
        <Stack direction="column" spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height={40} />
          ))}
        </Stack>
      </Box>
    </Grid>
    <Grid xs={12}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="outlined" color="primary" disabled>
            Cancel
          </Button>
          <Button variant="solid" color="primary" disabled>
            Save
          </Button>
      </Stack>
    </Grid>
  </Grid>
);

//Loading Skeletons for Summaries

export const SummaryCardSkeleton = () => (
  <Card variant='soft' sx={{ width:'100%', mb: 3, mt: 3, padding: '2.2rem' }}>
    <Grid container>
      <Grid xs={12} sm={2}>
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Skeleton variant="rectangular" width={60} height={50} />
        </Box>
        <Skeleton variant="text" width={100} />
      </Grid>
      <Grid xs={12} sm={10} className="card-separator">
        <Skeleton variant="rectangular" width={120} height={32} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="80%" />
      </Grid>
    </Grid>
  </Card>
);

export const QuestionTableSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    {[...Array(5)].map((_, index) => (
      <Skeleton key={index} variant="rectangular" width="100%" height={32} sx={{ mb: 2 }} />
    ))}
  </Box>
);

//Loading Skeletons for Settings

export const AdminsListSkeleton = ({ count = 2 }) => {
  return (
    <Box>
      <Typography level="h2">
        <Skeleton width={150} />
      </Typography>
      <List sx={{
        "--ListItem-paddingX": "0px",
        "--ListItem-paddingY": "8px"
      }}>
        {[...Array(count)].map((_, index) => (
          <ListItem
            key={index}
            endAction={
              <Skeleton variant="circular" width={32} height={32} />
            }
          >
            <Stack direction="row" spacing={1} alignItems="center" width="100%">
              <Skeleton variant="text" width={320} />
            </Stack>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CourseInfoSkeleton;