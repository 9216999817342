import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';

export default function DuplicateFilesModal( { modalOpen, closeModal, duplicatedFiles }) {

  return (
    <React.Fragment>
      <Modal open={modalOpen} onClose={closeModal}>
        <ModalDialog
          layout="fullscreen"
          variant="outlined"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <Grid container spacing={2} mx="auto">
            <Grid xs={12} justifyContent="flex-end">
              <ModalClose
                aria-label="Close Dialog"
               />
            </Grid>
            <Grid xs={12}>
              <Typography level="h1" mb={3}>Duplicate Files Identified</Typography>
            </Grid>
            <Grid xs={12}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                mb={4}
                >
                <Box>
                  <Typography level="body-lg">
                    Uploading duplicate files negatively impacts All Day TA’s ability to correctly answer questions.
                  </Typography>
                  <Typography level="body-lg">
                     Please rename the file you are trying to upload if it has unique content and try again.
                  </Typography>
                </Box>
                <Alert
                  startDecorator={<InfoIcon />}
                  variant="soft"
                  size="lg"
                >
                 You can replace the source file of existing files by clicking the edit button at the end  of the file's row 
                </Alert>
                <Typography level="title-lg">
                    The following files will not be uploaded:
                </Typography>
                <List sx={{  
                    "--ListItem-paddingX": "0px",
                    "--ListItem-paddingY": "8px",
                    }}>
                  {duplicatedFiles && duplicatedFiles.map((name, index) => (
                      <ListItem
                        key={index}
                      >
                        {name}
                      </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack 
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
                >
                  <Button
                    variant="outlined"
                    onClick={closeModal}>
                    Close
                  </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
