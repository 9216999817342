import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Input,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';

export default function CreateOrg() {
  const [orgName, setOrgName] = useState('');
  const [orgContact, setOrgContact] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    orgName: false,
    orgContact: false,
    orgEmail: false
  });

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    if (errors.orgName) {
      setErrors(prev => ({ ...prev, orgName: false }));
    }
  };

  const handleOrgContactChange = (e) => {
    setOrgContact(e.target.value);
    if (errors.orgContact) {
      setErrors(prev => ({ ...prev, orgContact: false }));
    }
  };

  const handleOrgEmailChange = (e) => {
    setOrgEmail(e.target.value);
    if (errors.orgEmail) {
      setErrors(prev => ({ ...prev, orgEmail: false }));
    }
  };

  const handleSave = async () => {
    console.log('handleSave function called');
    setIsSubmitted(true);

    const newErrors = {
      orgName: !orgName.trim(),
      orgContact: !orgContact.trim(),
      orgEmail: !orgEmail.trim(),
    };

    setErrors(newErrors);

    // If there are any errors, stop the submission
    if (newErrors.orgName || newErrors.orgContact || newErrors.orgEmail) {
      return;
    }

    const orgData = {
      orgName,
      orgContact,
      orgEmail
    };
    console.log('orgData prepared:', orgData);
  
    const formData = new FormData();
    formData.append('orgData', JSON.stringify(orgData));
    console.log('formData created:', formData);
  
    try {
      const response = await axios.post('/api/create_org', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setMessage('Organization created successfully!');
        setIsError(false);
        console.log('Server response:', response);
    
        // Clear the form (temporarily)
        setOrgName('');
        setOrgContact(['']);
        setOrgEmail('');
      }
      
    } catch (error) {
      setMessage('Error creating organization: ' + error);
      setIsError(true);
      console.error('Error details:', error);
    }
  };

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
            <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container>
        <Grid container xs={12} mx='auto' spacing={4} justifyContent="space-around"
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh', // Ensure full viewport height
          }}>
            <Grid xs={12} sm={8} md={6}>
              <Stack direction="column" spacing={3}>
              <Typography level="h1">Create an Organization</Typography>
              {message && (
                  <Alert
                      color={isError ? 'danger' : 'success'} 
                      sx={{ mb: 2 }}
                      role="alert"
                      aria-live="polite"
                      >
                      {message}
                  </Alert>
              )}
              <FormControl error={errors.orgName}>
                <FormLabel>Institution Name</FormLabel>
                <Input variant="outlined"
                  color=
                    {errors.orgName
                      ? "danger"
                      : "primary"
                      } 
                  required value={orgName} onChange={(e) => setOrgName(e.target.value)} />
                  <FormHelperText>
                    {errors.orgName
                    ? "Institution name is required"
                    : null }
                  </FormHelperText>
              </FormControl>
              <FormControl error={errors.orgContact}>
                <FormLabel>Contact Name</FormLabel>
                <Input variant="outlined"
                  color=
                    {errors.orgContact
                      ? "danger"
                      : "primary"
                      } 
                  required value={orgContact} onChange={(e) => setOrgContact(e.target.value)} />
                  <FormHelperText>
                    {errors.orgName
                    ? "Contact name is required"
                    : null }
                  </FormHelperText>
              </FormControl>
              <FormControl error={errors.orgEmail}>
                <FormLabel>Contact Email</FormLabel>
                <Input variant="outlined"
                  color=
                    {errors.orgEmail
                      ? "danger"
                      : "primary"
                      } 
                  required value={orgEmail} onChange={(e) => setOrgEmail(e.target.value)} />
                  <FormHelperText>
                    {errors.orgName
                    ? "Contact email is required"
                    : null
                    }
                  </FormHelperText>
              </FormControl>
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: '0.6rem'}}>
                    <Typography level="title-sm">Already created an organization?&nbsp;
                    <Link href="/signup/admin">
                      Create an Admin instead
                    </Link>
                    </Typography>
                </Box>
                <Button onClick={handleSave}>Save</Button>
              </Box>
              </Stack>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
