import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import theme from './theme/theme';
import CreateOrg from './pages/CreateOrg';
import CourseInfo from './pages/CourseInfo';
import Files from './pages/Files';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import Signup from './pages/Signup';
import Summaries from './pages/Summaries';
import Header from './components/Header';
import ProfessorLogin from './pages/ProfessorLogin';
import ProfessorSignUp from './pages/ProfessorSignUp';
import AdminAnalytics from './pages/AdminAnalytics';
import ResetPassword from './pages/ResetPassword';
import Chatbot from './components/Chatbot'; // change this later, to a page?
import CreateCourse from './pages/CreateCourse'
import Unauthorized from './pages/Unauthorized'
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import AdminProtectedRoute from './routes/ADTAProtectedRoute'
import StudentChatbot from './components/StudentChatbot';

function App() {
    const headerRef = useRef();
    const chatbotRef = useRef();
    const location = useLocation();
    const studentChatbotRef = useRef(null);
    const pathsWithoutHeader = ['/login/admin', '/sign-up', '/forgot-password', '/reset-password', '/create-course', '/signup/admin', '/create-org'];
    const [isLocalhost, setIsLocalhost] = useState(false);

    const handleCourseChange = () => {
        if (headerRef.current) {
            headerRef.current.refreshCourseList();
        }
    };

    const setOnCourseChange = (callback) => {
        if (headerRef.current) {
            headerRef.current.setOnCourseChange(callback);
        }
    };

    const isStudentChatbotRoute = () => {
        const specificRoutes = [
            '/preview', '/create-org', '/signup/admin', '/course-info', 
            '/create-course', '/files', '/', '/forgot-password', 
            '/settings', '/sign-up', '/summaries', '/login/admin', '/reset-password'
        ];
        return !specificRoutes.some(route => location.pathname.toLowerCase() === route.toLowerCase());
    };

    const shouldHideHeader = pathsWithoutHeader.includes(location.pathname)  || isStudentChatbotRoute();

    const getBaseUrl = () => {
        const origin = window.location.origin;
        if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
            return 'localhost';
        } else if (origin.includes('staging.alldayta.com')) {
            return 'https://staging.alldayta.com';
        } else {
            return 'https://app.alldayta.com';  
        }
    };

    useEffect(() => {
        const baseUrl = getBaseUrl();
        setIsLocalhost(baseUrl === 'localhost');
    }, []);

    return (
        <AuthProvider>
            <CssVarsProvider theme={theme}>
                <div className="App" style={{
                    backgroundColor: 'var(--joy-palette-background-body)',
                    minHeight: '100vh'}}>
                    {!shouldHideHeader && 
                        <Header onCourseChange={handleCourseChange} ref={headerRef} chatbotRef={chatbotRef}  />}
                    <Routes>
                        <Route path="/preview" element={
                            <ProtectedRoute>
                                <Chatbot ref={chatbotRef} />
                            </ProtectedRoute>
                        } />
                        <Route path='/create-org' element={
                            isLocalhost ? 
                            <CreateOrg />
                            :
                            <AdminProtectedRoute requiredOrgId={1}>
                                <CreateOrg />
                            </AdminProtectedRoute>
                        } />
                        <Route path='/signup/admin' element={
                            isLocalhost ? 
                            <ProfessorSignUp />
                            :
                            <AdminProtectedRoute requiredOrgId={1}>
                                <ProfessorSignUp />
                            </AdminProtectedRoute>
                        } />
                        <Route path='/sign-up' element={
                            isLocalhost ? 
                            <Signup />
                            :
                            <AdminProtectedRoute requiredOrgId={1}>
                                <Signup />
                            </AdminProtectedRoute>
                        } />
                        <Route path='/analytics' element={
                            isLocalhost ? 
                            <AdminAnalytics />
                            :
                            <AdminProtectedRoute requiredOrgId={1}>
                                <AdminAnalytics />
                            </AdminProtectedRoute>
                        } />
                        <Route path='/preview' element={
                            <ProtectedRoute>
                                <Chatbot ref={chatbotRef} />
                            </ProtectedRoute>
                        } />
                        <Route path='/course-info' element={
                            <ProtectedRoute>
                                <CourseInfo headerRef={headerRef} />
                            </ProtectedRoute>
                        } />
                        <Route path='/create-course' element={
                            <ProtectedRoute>
                                <CreateCourse headerRef={headerRef}/>
                            </ProtectedRoute>
                        } />
                        <Route path='/files' element={
                            <ProtectedRoute>
                                <Files headerRef={headerRef} />
                            </ProtectedRoute>
                        }/>
                        <Route path='/' element={
                            <ProtectedRoute>
                                <Files headerRef={headerRef} />
                            </ProtectedRoute>
                        }/>
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/settings' element={
                            <ProtectedRoute>
                                <Settings headerRef={headerRef} />
                            </ProtectedRoute>
                        }/>
                        <Route path='/summaries' element={
                            <ProtectedRoute>
                                <Summaries setOnCourseChange={setOnCourseChange}/>
                            </ProtectedRoute>
                        } />
                        <Route path='/login/admin' element={<ProfessorLogin />} />
                        <Route path='/reset-password' element={<ResetPassword />} />
                        <Route path='/unauthorized' element={<Unauthorized />} />
                        <Route path="*" element={<StudentChatbot isStudentChatbot={true} ref={studentChatbotRef} />} />
                    </Routes>
                </div>
            </CssVarsProvider>
        </AuthProvider>
    );
}

export default App;