import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Card,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Typography,
  Sheet,
  Chip,
  Divider,
  Stack
} from '@mui/joy';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import Container from '../components/Container';

export default function Analytics() {
  const [filters, setFilters] = useState({
    org_id: '',
    admin_id: '',
    course_id: '',
    start_time: '',
    end_time: ''
  });
  
  const [filterOptions, setFilterOptions] = useState({
    organizations: [],
    admins: [],
    courses: []
  });
  
  const [questions, setQuestions] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const params = {
          ...(filters.org_id && { org_id: filters.org_id }),
          ...(filters.admin_id && { admin_id: filters.admin_id })
        };
        const response = await axios.get('/api/super/analytics/filters', { params });
        setFilterOptions(response.data);
      } catch (error) {
        setError("Error fetching filter options");
      }
    };
    fetchFilterOptions();
  }, [filters.org_id, filters.admin_id]);

  // Fetch questions and metrics
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('/api/super/analytics/questions', { params: filters });
        setQuestions(response.data.questions);
        processMetrics(response.data.questions);
      } catch (error) {
        setError("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [filters]);

  // Process metrics from questions data
  const processMetrics = (questionData) => {
    const uniqueIPs = new Set(questionData.map(q => q.ipaddress));
    
    // Initialize metrics object
    const metrics = {
      total: questionData.length,
      syllabusQuestions: questionData.filter(q => q.is_syllabus).length,
      followUps: questionData.filter(q => q.is_follow_up).length,
      uniqueIPs: uniqueIPs.size,
      languages: {},
      weeklyDistribution: {}
    };

    // Get the start of the current year
    const currentYear = new Date().getFullYear();
    const startOfYear = new Date(currentYear, 0, 1);

    // Initialize all weeks of the year with 0
    for (let week = 1; week <= 53; week++) {
      metrics.weeklyDistribution[week] = 0;
    }

    // Calculate weekly distribution
    questionData.forEach(q => {
      const questionDate = new Date(q.timestamp);
      // Only process questions from current year
      if (questionDate.getFullYear() === currentYear) {
        // Calculate week number (1-53)
        const weekNumber = getWeekNumber(questionDate);
        metrics.weeklyDistribution[weekNumber] = (metrics.weeklyDistribution[weekNumber] || 0) + 1;
      }
    });

    setMetrics(metrics);
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Helper function to get the start and end dates of a week
  const getWeekRange = (weekNum) => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    
    // Adjust first day to previous Sunday if necessary
    const dayOffset = firstDayOfYear.getDay();
    const firstWeekStart = new Date(firstDayOfYear);
    firstWeekStart.setDate(firstWeekStart.getDate() - dayOffset);
    
    // Calculate start date for the given week
    const weekStart = new Date(firstWeekStart);
    weekStart.setDate(weekStart.getDate() + (weekNum - 1) * 7);
    
    // Calculate end date (6 days after start)
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);
    
    return { start: weekStart, end: weekEnd };
  };

  // Helper function to format date range label
  const formatWeekLabel = (week) => {
    const { start, end } = getWeekRange(week);
    const formatDate = (date) => {
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <Container>
      <Box sx={{ p: 2 }}>
        {/* Header */}
        <Typography level="h1" sx={{ mb: 4 }}>Analytics Dashboard</Typography>

        {/* Error Alert */}
        {error && (
          <Alert color="danger" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Filters Section */}
        <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
          <Typography level="h4" sx={{ mb: 2 }}>Filters</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Organization</FormLabel>
                <Select
                  value={filters.org_id}
                  onChange={(_, value) => setFilters(prev => ({ ...prev, org_id: value, admin_id: '', course_id: '' }))}
                >
                  <Option value="">All Organizations</Option>
                  {filterOptions.organizations.map(org => (
                    <Option key={org.id} value={org.id.toString()}>{org.name}</Option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Admin</FormLabel>
                <Select
                  value={filters.admin_id}
                  onChange={(_, value) => setFilters(prev => ({ ...prev, admin_id: value, course_id: '' }))}
                  disabled={!filters.org_id}
                >
                  <Option value="">All Admins</Option>
                  {filterOptions.admins
                    .filter(admin => !filters.org_id || admin.org_id.toString() === filters.org_id)
                    .map(admin => (
                      <Option key={admin.id} value={admin.id.toString()}>{admin.name}</Option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <FormLabel>Course</FormLabel>
                <Select
                  value={filters.course_id}
                  onChange={(_, value) => setFilters(prev => ({ ...prev, course_id: value }))}
                  disabled={!filters.org_id}
                >
                  <Option value="">All Courses</Option>
                  {filterOptions.courses
                    .filter(course => !filters.org_id || course.org_id.toString() === filters.org_id)
                    .map(course => (
                      <Option key={course.id} value={course.id.toString()}>{course.name}</Option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>

        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {/* Metrics Overview */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid xs={12} md={3}>
                <Card variant="soft">
                  <Typography level="h4">Total Questions</Typography>
                  <Typography level="h2">{metrics?.total || 0}</Typography>
                </Card>
              </Grid>
              <Grid xs={12} md={3}>
                <Card variant="soft">
                  <Typography level="h4">Syllabus Questions</Typography>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography level="h2">{metrics?.syllabusQuestions || 0}</Typography>
                    <Typography level="body-md" sx={{ color: 'neutral.500' }}>
                      {metrics ? ((metrics.syllabusQuestions / metrics.total) * 100).toFixed(1) + '%' : '0%'}
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid xs={12} md={3}>
                <Card variant="soft">
                  <Typography level="h4">Follow-ups</Typography>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography level="h2">{metrics?.followUps || 0}</Typography>
                    <Typography level="body-md" sx={{ color: 'neutral.500' }}>
                      {metrics ? ((metrics.followUps / metrics.total) * 100).toFixed(1) + '%' : '0%'}
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid xs={12} md={3}>
                <Card variant="soft">
                  <Typography level="h4">Unique IPs</Typography>
                  <Typography level="h2">{metrics?.uniqueIPs || 0}</Typography>
                </Card>
              </Grid>
            </Grid>

            {/* Weekly Distribution Chart */}
            {metrics?.weeklyDistribution && (
              <Card variant="outlined" sx={{ mb: 4, height: 400, p: 2 }}>
                <Typography level="h4" sx={{ mb: 2 }}>Questions per Week ({new Date().getFullYear()})</Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart 
                    data={Object.entries(metrics.weeklyDistribution).map(([week, count]) => ({
                      week: parseInt(week),
                      count: count,
                      dateRange: formatWeekLabel(parseInt(week)) // Add formatted date range
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 45, // Increased bottom margin for rotated labels
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="dateRange"
                      interval={4}  // Show every 4th week
                      angle={-45}
                      textAnchor="end"
                      height={60} // Increased height for labels
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis />
                    <RechartsTooltip 
                      formatter={(value, name) => [`${value} questions`, 'Volume']}
                      labelFormatter={(dateRange) => dateRange}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="count" 
                      stroke="#8884d8" 
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            )}

            {/* Questions List */}
            <Card variant="outlined">
              <Typography level="h4" sx={{ mb: 2 }}>Questions ({questions.length})</Typography>
              <Box sx={{ mt: 2 }}>
                {questions.map((question) => (
                  <Card key={question.question_id} variant="soft" sx={{ mb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Box sx={{ display: 'flex', gap: 1, mb: 1, flexWrap: 'wrap' }}>
                          <Typography level="title-md">{question.course_name}</Typography>
                          <Chip size="md" startDecorator={<LanguageRoundedIcon />}>
                            {question.language}
                          </Chip>
                          {question.is_syllabus && (
                            <Chip size="md" color="primary" startDecorator={<MenuBookRoundedIcon />}>
                              Syllabus Question
                            </Chip>
                          )}
                          {question.response_rating !== null && (
                            <Chip 
                              size="md" 
                              color={question.response_rating ? "success" : "danger"}
                              startDecorator={question.response_rating ? 
                                <CheckRoundedIcon /> : 
                                <CloseRoundedIcon />
                              }
                            >
                                {question.response_rating ? "Helpful" : "Not Helpful"}
                            </Chip>
                          )}
                          {question.is_follow_up && (
                            <Chip size="md" color="success" startDecorator={<ForumRoundedIcon />}>
                              Follow-up
                            </Chip>
                          )}
                          <Chip size="md" startDecorator={<SmartToyRoundedIcon />}>
                            {question.llm_used}
                          </Chip>
                          <Chip size="md">
                            Similarity: {(question.similarity_score * 100).toFixed(1)}%
                          </Chip>
                          </Box>

                          <Typography level="body-sm" sx={{ mb: 1 }}>
                            {new Date(question.timestamp).toLocaleString()}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography level="title-md" sx={{ mb: 1 }}>Question:</Typography>
                        <Typography level="body-md">{question.question}</Typography>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Typography level="title-md" sx={{ mb: 1 }}>Answer:</Typography>
                        <Typography level="body-md">{question.answer}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Box>
            </Card>
          </>
        )}
      </Box>
    </Container>
  );
}