import React, { useState, useEffect, useCallback } from 'react';
import {Typography} from '@mui/joy';

const useRotatingMessages = (messages, interval = 1250) => {
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [isPaused, setIsPaused] = useState(false);

  const rotateMessage = useCallback(() => {
    if (!isPaused) {
      setCurrentMessage(prevMessage => {
        const currentIndex = messages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % messages.length;
        return messages[nextIndex];
      });
    }
  }, [messages, isPaused]);

  useEffect(() => {
    const timer = setInterval(rotateMessage, interval);
    return () => clearInterval(timer);
  }, [rotateMessage, interval]);

  const pauseRotation = () => setIsPaused(true);
  const resumeRotation = () => setIsPaused(false);

  return { currentMessage, pauseRotation, resumeRotation };
};

const RotatingMessages = ({ messages }) => {
  const { currentMessage, pauseRotation, resumeRotation } = useRotatingMessages(messages);

  return (
    <Typography
      onMouseEnter={pauseRotation}
      onMouseLeave={resumeRotation}
    >
      {currentMessage}
    </Typography>
  );
};

export default RotatingMessages;