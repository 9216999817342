// this file is for editing a course
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  DialogTitle, 
  DialogContent, 
  DialogActions,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal, 
  ModalDialog, 
  ModalClose, 
  Option,
  Select,
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import Container from "../components/Container";
import LoadingSkeleton from "../components/LoadingSkeletons";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getOrgName } from '../OrgRetrieveAPI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CourseCheck from '../components/CourseCheck';

const dateToString = (dateObj) => {
  return `${dateObj.year}-${dateObj.month}-${dateObj.day}`;
};

// Updated slugify function based on the provided Python implementation
const slugify = (text) => {
  // Convert to lowercase
  let slug = text.toLowerCase();
  // Replace spaces with hyphens
  slug = slug.replace(/\s/g, '-');
  // Remove any characters that are not alphanumeric, hyphens, underscores, or forward slashes
  slug = slug.replace(/[^\w\-_/]/g, '');
  // Remove leading and trailing hyphens
  slug = slug.replace(/^-+|-+$/g, '');
  // Replace multiple consecutive hyphens with a single hyphen
  slug = slug.replace(/-+/g, '-');
  // Remove leading and trailing forward slashes
  slug = slug.replace(/^\/+|\/+$/g, '');
  return slug;
};

export default function CourseInfo({ headerRef }) {
  //console.log("CourseInfo component mounted");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [org_Id, setOrgId] = useState(null);
  const [course_Id, setCourseId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [modules, setModules] = useState([""]);
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState("");
  const [termName, setTermName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // Used for course URL
  const [orgName, setOrgName] = useState('');
  const [slugifiedOrgName, setSlugifiedOrgName] = useState('');
  const [courseUrl, setCourseUrl] = useState('');
  const [originalCourseUrl, setOriginalCourseUrl] = useState('');
  const [slugifiedUrl, setSlugifiedUrl] = useState('');
  const [editableCourseUrl, setEditableCourseUrl] = useState('');
  const [fixedUrlPart, setFixedUrlPart] = useState('');
  const [fullSlugifiedUrl, setFullSlugifiedUrl] = useState('');
  const [urlChanged, setUrlChanged] = useState(false);
  const [currentCourseUrl, setCurrentCourseUrl] = useState('');
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [dateError, setDateError] = useState('');
  const [statusMessage, setStatusMessage] = useState("");
  const [showSlugAlert, setShowSlugAlert] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
    dateError: false
  });

  useEffect(() => {
      document.title = "Edit Course Information - All Day TA";
  }, []);

  const parseDateString = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = date.toLocaleString("default", {
      month: "short",
      timeZone: "UTC",
    });
    const year = String(date.getUTCFullYear());

    return { day, month, year };
  };

  const retrieveCourseInfo = useCallback(async () => {
  //console.log("retrieveCourseInfo called");
  setIsLoading(true);
  try {
    const response = await axios.get("/api/course");
    //console.log("Response received from /api/course", response);
    if (response.data && response.data.length > 0) {
      //console.log("Response data:", response.data);
      const courseData = response.data[0];
      const [
        orgId,
        courseId,
        courseName,
        taNames,
        topics,
        term,
        active,
        startDate,
        endDate,
        professor_names,
        description,
        courseUrl,
      ] = courseData;

      //console.log("Course data received:", courseData);

      setOrgId(orgId);
      setCourseId(courseId);
      setCourseName(courseName);
      setProfessors(professor_names.toString().replaceAll(",", ", "));
      setModules(topics);
      setTermName(term);
      setDescription(description);
      setCourseUrl(courseUrl);
      setTeachingAssistants(taNames.toString().replaceAll(",", ", "));
      setStartDate(parseDateString(startDate));
      setEndDate(parseDateString(endDate));
      setCourseUrl(courseUrl);
      setOriginalCourseUrl(courseUrl);
      setCurrentCourseUrl(courseUrl);
      console.log("Original course URL set:", courseUrl);

    } else {
      //console.log("Response data is empty");
    }
  } catch (error) {
    //console.error("Error fetching course info:", error);
  } finally {
    setIsLoading(false);
  }
}, []);

  useEffect(() => {
       //console.log("useEffect called");
	retrieveCourseInfo();
      if (headerRef.current) {
          headerRef.current.setOnCourseChange(retrieveCourseInfo);
      }
  }, [headerRef, retrieveCourseInfo]);


  // Required Fields Validation
  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
    if (errors.courseName) {
      setErrors(prev => ({ ...prev, courseName: false }));
    }
  };

  const handleProfessorNamesChanges = (e) => {
    setProfessors(e.target.value);
    if (errors.professors) {
      setErrors(prev => ({ ...prev, professors: false }));
    }
  };

  
  //Course URL
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const name = await getOrgName();
        setOrgName(name);
        setSlugifiedOrgName(slugify(name));
      } catch (error) {
        console.error('Error fetching organization name:', error);
        // Handle the error appropriately in your UI
      }
    };

    fetchOrgName();
  }, []);

  useEffect(() => {
    if (courseUrl) {
      const urlParts = courseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
    }
  }, [courseUrl]);

  useEffect(() => {
    if (currentCourseUrl) {
      const urlParts = currentCourseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
      console.log("URL parts set:", { editablePart, fixedPart });
    }
  }, [currentCourseUrl]);

  const handleCourseUrlChange = (e) => {
    const newEditablePart = e.target.value;
    setEditableCourseUrl(newEditablePart);
    const newFullUrl = `${fixedUrlPart}/${newEditablePart}`;
    setCurrentCourseUrl(newFullUrl);
    setFullSlugifiedUrl(`app.alldayta.com${newFullUrl}`);
    setShowSlugAlert(true);
    const hasChanged = newFullUrl !== originalCourseUrl;
    setUrlChanged(hasChanged);
    console.log("URL changed:", { newFullUrl, originalCourseUrl, hasChanged });
    setUrlError("");
  };

  const handleCourseUrlBlur = () => {
    if (editableCourseUrl !== originalCourseUrl) {
      setShowSlugAlert(true);
    }
  };

  const copyToClipboard = async () => {
      try {
          await navigator.clipboard.writeText(fullSlugifiedUrl);
          setSnackbarOpen(true);
      } catch (err) {
          //console.error('Failed to copy: ', err);
      }
  };

  //Start and End Dates
  useEffect(() => {
    const startDateObj = new Date(`${startDate.year}-${startDate.month}-${startDate.day}`);
    const newEndDateObj = new Date(`${endDate.year}-${endDate.month}-${endDate.day}`);
    setEndDate({
      day: String(newEndDateObj.getDate()).padStart(2, '0'),
      month: newEndDateObj.toLocaleString('default', { month: 'short' }),
      year: String(newEndDateObj.getFullYear())
    });
    setDateError('');
  }, [startDate]);

  const handleStartDateChange = (field) => (_, newValue) => {
    setStartDate(prevDate => ({...prevDate, [field]: newValue}));
  };

  const handleEndDateChange = (field) => (_, newValue) => {
    setEndDate(prevDate => ({...prevDate, [field]: newValue}));
  };

  useEffect(() => {
    validateDates();
  }, [startDate, endDate]);

  const validateDates = () => {
    const start = new Date(dateToString(startDate));
    const end = new Date(dateToString(endDate));
    if (end < start) {
      setDateError('End date cannot be before the start date');
      setErrors(prev => ({ ...prev, dateError: true }));
    } else {
      setDateError('');
      setErrors(prev => ({ ...prev, dateError: false }));
    }
  };

  //Modules - Not implemented yet

  const handleAddModule = () => {
    setModules([...modules, ""]);
  };

  const handleModuleChange = (index, value) => {
    const updatedModules = [...modules];
    updatedModules[index] = value;
    setModules(updatedModules);
  };

const handleSave = async () => {
  //console.log("Saving the updated course information...");
  setIsSubmitted(true);

  const newErrors = {
    courseName: !courseName.trim(),
    professors: !professors.trim(),
    dateError: !!dateError
  };

  setErrors(newErrors);

  if (newErrors.courseName || newErrors.professors || newErrors.dateError) {
    return;
  }

  const trimmedDescription = description.trim();

  const fullCourseUrl = `${slugifiedOrgName}/${slugifiedUrl}`;

  const courseData = {
    courseName,
    trimmedDescription,
    professors,
    teachingAssistants,
    modules,
    termName,
    startDate,
    endDate,
    courseUrl: urlChanged ? currentCourseUrl : undefined,
    urlChanged
  };

  //console.log("courseData prepared:", courseData);

  try {
    //console.log("Sending POST request to /api/course_info");
    const response = await axios.patch("/api/course_info", courseData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    //console.log("Response received:", response);

    if (response.status === 200) {
      const courseId = response.data.course.course_id;

      navigate("/files");
      setStatusMessage("Course data updated successfully");
      //console.log("Server response:", response);
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
      setUrlError("This URL already exists. Please choose a different one.");
      if (error.response.data.suggested_url) {
          setCurrentCourseUrl(error.response.data.suggested_url);
          const urlParts = error.response.data.suggested_url.split('/');
          setEditableCourseUrl(urlParts[urlParts.length - 1]);
          setFullSlugifiedUrl(`app.alldayta.com${error.response.data.suggested_url}`);
        }
    } else {
      setStatusMessage("Error saving course data: " + (error.response?.data?.message || error.message));
    }
  }
};

const handleDeleteCourse = async () => {
  try {
    //console.log(`Attempting to delete course: /api/delete/course/${org_Id}/${course_Id}`);
    const response = await axios.delete(`/api/delete/course/${org_Id}/${course_Id}`);
    if (response.status === 200) {
      setStatusMessage("Course deleted successfully");
      navigate("/create-course"); // Redirect to the create a course page
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    } else {
      setStatusMessage("Error deleting course: " + response.statusText);
    }
  } catch (error) {
    setStatusMessage("Error deleting course: " + error.response.data.message);
    //console.error("Error details:", error.response.data);
  }
  setIsDeleteDialogOpen(false);
};

return (
  <Container>
    {isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ flexWrap: "wrap" }}
          >
            <Typography level="h1" id="edit-course-form">Edit Course Information</Typography>
          </Stack>
        </Grid>
        <CourseCheck> 
        <Grid
          xs={12}
          sm={6}
          pd={3}
          sx={{ boxSizing: "border-box", width: "100%" }}
        >
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl error={errors.courseName}>
              <FormLabel>Course Name (Required)</FormLabel>
              <Input
                variant="outlined"
                color={errors.courseName ? "danger" : "primary"}
                required
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
              <FormHelperText>
                {errors.courseName
                  ? "Course name is required"
                  : "Course name as it will be presented to students"}
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Course Description</FormLabel>
              <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description" />
            </FormControl>

            <Box sx={{ mb: 3, textAlign: "left" }}>
              <Typography level="component-md" mb={1} id="course-start-date">Course Start Date</Typography>
              <Box 
                sx={{ display: "flex", gap: 2 }}
                role="group"
                aria-labelledby="course-start-date"
                >
                <FormControl>
                  <FormLabel>Day</FormLabel>
                  <Select
                    variant="outlined"
                    color="primary"
                    value={startDate.day}
                    onChange={handleStartDateChange('day')}
                  >
                    {[...Array(31)].map((_, i) => (
                      <Option
                        key={i.toString()}
                        value={(i + 1).toString().padStart(2, "0")}
                      >
                        {(i + 1).toString().padStart(2, "0")}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Month</FormLabel>
                  <Select
                    value={startDate.month}
                    onChange={handleStartDateChange('month')}
                    variant="outlined"
                    color="primary"
                  >
                    {[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ].map((month) => (
                      <Option key={month.toString()} value={month}>
                        {month}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Year</FormLabel>
                  <Select
                    value={startDate.year}
                    onChange={handleStartDateChange('year')}
                    variant="outlined"
                    color="primary"
                  >
                    {[2024, 2025, 2026, 2027, 2028].map((year) => (
                      <Option key={year.toString()} value={year.toString()}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ mb: 3, textAlign: "left" }}>
              <Typography level="component-md" mb={1} id="course-end-date">Course End Date</Typography>
              {dateError && <Typography mb={1} color="danger" level="component-sm" data-testid="date-error-message">{dateError}</Typography>}
              <Box 
                sx={{ display: "flex", gap: 2 }}
                role="group"
                aria-labelledby="course-end-date"
                >
                <FormControl error={!!dateError}>
                  <FormLabel>Day</FormLabel>
                  <Select
                    value={endDate.day}
                    onChange={handleEndDateChange('day')}
                    variant="outlined"
                    color="primary"
                  >
                    {[...Array(31)].map((_, i) => (
                      <Option
                        key={i.toString()}
                        value={(i + 1).toString().padStart(2, "0")}
                      >
                        {(i + 1).toString().padStart(2, "0")}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl error={!!dateError}>
                  <FormLabel>Month</FormLabel>
                  <Select
                    value={endDate.month}
                    onChange={handleEndDateChange('month')}
                    variant="outlined"
                    color="primary"
                  >
                    {[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ].map((month) => (
                      <Option key={month.toString()} value={month}>
                        {month}
                      </Option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl error={!!dateError}>
                  <FormLabel>Year</FormLabel>
                  <Select
                    value={endDate.year}
                    onChange={handleEndDateChange('year')}
                    variant="outlined"
                    color="primary"
                  >
                    {[2024, 2025, 2026, 2027, 2028].map((year) => (
                      <Option key={year.toString()} value={year.toString()}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Stack>
        </Grid>

        <Grid xs={12} sm={6}>
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl error={errors.professors}>
              <FormLabel>Professor Name(s) (Required)</FormLabel>
              <Input 
                variant="outlined" 
                color={errors.professors ? "danger" : "primary"} 
                required 
                value={professors} 
                onChange={(e) => setProfessors(e.target.value)} 
              />
              <FormHelperText>
                {errors.professors
                  ? "Professor name(s) are required"
                  : "Add multiple names separated with a comma"}
              </FormHelperText>
            </FormControl>
            
            <FormControl>
              <FormLabel>Teaching Assistant Name(s)</FormLabel>
              <Input
                variant="outlined"
                color="primary"
                value={teachingAssistants}
                onChange={(e) => setTeachingAssistants(e.target.value)}
              />
              <FormHelperText>
                Add multiple names separated with a comma
              </FormHelperText>
            </FormControl>

            <FormControl error={!!urlError}>
              <FormLabel>Course URL (Student Access)</FormLabel>
              <Stack
                direction={{ xs: 'column', sm: 'column', md:'column', lg:'row'}} 
                justifyContent="flex-start" 
                alignItems={{ xs: 'flex-start', lg:'center'}}
                mt={1}
                >
                  <Typography>app.alldayta.com{fixedUrlPart}/</Typography>
                  <Input
                    variant="outlined"
                    color={urlError ? "danger" : "primary"}
                    value={editableCourseUrl}
                    onChange={handleCourseUrlChange}
                    onBlur={handleCourseUrlBlur}
                    sx={{ display:'flex', flexGrow:'1'}}
                  />
              </Stack>
              {urlError && (
                <FormHelperText sx={{ color: 'danger' }}>
                  {urlError}
                </FormHelperText>
              )}
            </FormControl>
            {showSlugAlert && (
              <Alert
                sx={{ mt: 1 }}
                role="alert"
                aria-live="polite"
                endDecorator={
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={copyToClipboard}
                    endDecorator={<ContentCopyIcon />}
                  >
                    Copy
                  </Button>
                }
              >
                <strong>Your URL:</strong> {fullSlugifiedUrl}
              </Alert>
            )}

            <FormControl>
              <FormLabel>Term Name</FormLabel>
              <Input variant="outlined" color="primary" value={termName} onChange={(e) => setTermName(e.target.value)} />
            </FormControl>
          </Stack>
          {/*Hide Modules until implemented everywhere
          <Box sx={{ mt:3, mb: 3, textAlign: "left" }}>
            <Stack direction="column" spacing={1} alignItems="flex-start">
              {modules.map((module, index) => (
                <FormControl key={index} sx={{ width: "100%" }}>
                  <FormLabel>{`Module ${index + 1}`}</FormLabel>
                  <Input
                    value={module}
                    onChange={(e) =>
                      handleModuleChange(index, e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    color="primary"
                  />
                </FormControl>
              ))}
              <Button
                variant="plain"
                color="primary"
                startDecorator={<AddIcon />}
                onClick={handleAddModule}
              >
                Add Module
              </Button>
            </Stack>
          </Box>*/}
        </Grid>

        <Grid
          xs={12}
          mb={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="plain" color="danger" onClick={() => setIsDeleteDialogOpen(true)} endDecorator={<DeleteOutlineIcon />}>
              Delete Course
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate("/files")}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography>{statusMessage}</Typography>
        </Grid>
        </CourseCheck>
      </Grid>
    )}
    
    <Modal open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
      <ModalDialog>
        <DialogTitle>Confirm Course Deletion</DialogTitle>
        <ModalClose />
        <DialogContent>
          Are you sure you want to delete this course? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleDeleteCourse}>
            Delete
          </Button>
          <Button variant="outlined" color="neutral" onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
    <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        className="url-copied"
        data-cy="copy-success-snackbar"
    >
        URL copied to clipboard!
    </Snackbar>
  </Container>
);
}
