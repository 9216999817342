import axios from 'axios';

export const getOrganizations = async () => {
  try {
    const response = await axios.get('/api/organizations');
    return response.data;
  } catch (error) {
    //console.error('Error fetching organizations:', error);
    throw error;
  }
};

export const getOrgName = async () => {
  try {
    const organizations = await getOrganizations();
    // Assuming the first organization in the list is the current user's organization
    if (organizations && organizations.length > 0) {
      return organizations[0].org_name;
    }
    throw new Error('No organization found');
  } catch (error) {
    //console.error('Error fetching organization name:', error);
    throw error;
  }
};
