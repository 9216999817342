import axios from 'axios';
import { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalClose,
  ModalDialog,
  Typography
} from '@mui/joy';
import Files from '../pages/Files';

export default function DeleteFileModal({ open, onClose, files, onDelete, onConfirmDelete }) {

  const handleDelete = async () => {
    if (!files || files.length === 0) return; // If it's undefined or null, return

    try {
      // Update the status of all files to "Currently Deleting..."
      files.forEach(file => onDelete(file));

      // Close the modal
      onClose();

      // Prepare the files for deletion
      const filesToDelete = files.map(file => ({
        org_id: file.org_id,
        course_id: file.course_id,
        file_id: file.file_id,
      }));

      // Delete the files
      await axios.delete('/api/delete_files', { data: { files: filesToDelete } });

      // Confirm deletion for each file
      files.forEach(file => onConfirmDelete(file));

      // console.log(`Successfully deleted ${files.length} file(s)`);
    } catch (error) {
      // console.error('Error deleting file:', error);
    }
  };
  
  return (
      <Modal open={open} onClose={onClose}>
        <ModalDialog>
          <DialogTitle>Confirm File Deletion</DialogTitle>
          <ModalClose />
          <DialogContent>
                <Typography>
                  Are you sure you want to delete the following file{files?.length > 1 ? 's' : ''}?
                </Typography>
                <ul>
                  {files && files.map((file, index) => (
                    <li key={file.file_id}>
                      <Typography>{file.title}</Typography>
                    </li>
                  ))}
                </ul>
                <Typography>The content from selected files will no longer contribute to answering students' questions</Typography>
              </DialogContent>
            <DialogActions>
                <Button variant="solid" color="danger" onClick={handleDelete}>Delete</Button>
                <Button variant="outlined" color="neutral" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </ModalDialog>
      </Modal>
  );
}
